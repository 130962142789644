
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;800;700;600;500;400900&display=swap');
.App {
  text-align: center;
}

.number-text{
  color: #01718f;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

html{
  background-color: #003A44;
  font-family: 'Open Sans', sans-serif;
}
.react-sweet-progress-symbol
{
  font-weight: 300 !important;
}

